
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap');
:root {
  font-size: 12pt;
}
* {
  box-sizing: border-box;
}
:lang(ja) :lang(en) {
  display: none;
}
:lang(en) :lang(ja) {
  display: none;
}
html {
  color: #061607;
  font-family: "Yu Gothic Bold", "游ゴシック Bold", YuGothic, "游ゴシック体", sans-serif;
  font-weight: 700;
  line-height: 1.75;
}
@media all and (max-width: 768px) {
  html {
    font-family: 'Noto Sans JP', sans-serif;
  }
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  padding: 5rem;
  font-size: 0.875rem;
}
@media all and (max-width: 768px) {
  body {
    padding: 1rem;
  }
}
main {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h1 {
  font-size: 1em;
}
p {
  margin-top: 1.5rem;
}
section {
  position: relative;
  margin: 0;
  padding-right: calc(50% + 40px);
  z-index: 2;
}
@media all and (max-width: 768px) {
  section {
    padding-right: 0;
  }
}
section:first-child {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media all and (max-width: 768px) {
  section:first-child {
    height: calc(100% - 66.67vw + 1.333rem);
  }
}
a {
  color: inherit;
  text-decoration: none;
  position: relative;
}
a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  background: #061607;
  top: 1rem;
  left: 0;
  transition: width 0.2s ease-out;
}
a:hover:after {
  width: 100%;
}
figure {
  margin: 0;
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 640px;
  height: 0;
  padding: 33.34% 0;
  vertical-align: top;
}
img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
}
figcaption {
  position: absolute;
  display: block;
  width: 100%;
  top: calc(100% + 0.25rem);
  right: 0;
  text-align: right;
  font-size: 66.667%;
}
small {
  font-size: 80%;
}
table {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}
th,
td {
  text-align: left;
  vertical-align: top;
}
th {
  width: 3em;
}
td {
  padding-left: 0.5rem;
}
button {
  appearance: none;
  background: #fff;
  padding: 0;
}
.wrapper {
  display: flex;
  justify-content: stretch;
  height: 50%;
}
@media all and (max-width: 768px) {
  .wrapper {
    height: 100%;
    padding: 1rem;
  }
}
.content {
  position: relative;
  width: 100%;
  flex: 1 0 100%;
  overflow-y: scroll;
}
.fade {
  transition: opacity 0.5s;
}
.fade.out {
  opacity: 0;
}
.image {
  position: fixed;
  right: 5rem;
  top: 50%;
  transform: translateY(-50%);
  width: calc(50% - 80px);
  z-index: 0;
  text-align: right;
}
@media all and (max-width: 768px) {
  .image {
    display: none !important;
  }
}
.bgline {
  background: #061607;
  position: absolute;
}
.bgline-1 {
  left: -1rem;
  top: -0.5px;
  width: 2rem;
  height: 1px;
}
.bgline-2 {
  left: -0.5px;
  top: -1rem;
  width: 1px;
  height: 2rem;
}
.bgline-3 {
  right: -1rem;
  bottom: -0.5px;
  width: 2rem;
  height: 1px;
}
.bgline-4 {
  right: -0.5px;
  bottom: -1rem;
  width: 1px;
  height: 2rem;
}
.tr {
  position: absolute;
  right: -0.5px;
  top: -0.5px;
  display: flex;
  gap: 8px;
}
.ig {
  line-height: 1rem;
  width: calc(1rem + 2px);
  height: calc(1rem + 2px);
  display: block;
}
.ig:after {
  content: none;
}
.ig > img {
  vertical-align: top;
  width: calc(1rem + 2px);
  height: calc(1rem + 2px);
}
.lang {
  box-sizing: content-box;
  border: 1px solid;
  font-size: 70%;
  width: 1rem;
  white-space: nowrap;
  line-height: 1rem;
  text-align: center;
  cursor: pointer;
}
.lang:hover {
  text-decoration: none;
}
.sticky {
  display: none;
}
@media all and (max-width: 768px) {
  .sticky {
    display: block;
    position: sticky;
    top: 0;
    z-index: 4;
    background: #fff;
    padding-bottom: 1.5rem;
    font-size: 90%;
  }
}
